import { mapMutations, mapState } from "vuex";
import { GRID_CONFIGS } from "@/constants/gridConfigs";
import { MUTATIONS } from "@/constants/vuex";

export default {
  props: {
    widget: {
      type: Object,
    },
    widgetType: {
      type: String,
    },
  },
  computed: {
    ...mapState("dashboard", ["layout"]),
    currentLayout() {
      return this.layout.find(({ i: layoutId }) => layoutId === this.widget.id);
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    ...mapMutations({
      makerFixLayoutHeight: `dashboard/${MUTATIONS.DASHBOARD.FIX_LAYOUT_HEIGHT}`,
    }),
    initialize() {
      const widgetId = this?.widget?.id;
      this.updateHeightWidget(widgetId);
    },
    observeWidgetHeight() {
      const widgetId = this?.widget?.id;
      const resizeObserver = new ResizeObserver(() => {
        this.updateHeightWidget(widgetId);
      });

      resizeObserver.observe(document.getElementById(widgetId));
    },
    updateHeightWidget(widgetId) {
      const MARGIN_HEADER = 16;
      const [MARGIN_CONTAINER] = GRID_CONFIGS.margin;
      const ROW_HEIGHT = GRID_CONFIGS.rowHeight;

      // avoid crash system when initialize doesnt have widget yet
      if (!widgetId) return;

      const elementWidget = document.getElementById(widgetId);
      const { clientHeight: widgetHeight } = elementWidget || {
        clientHeight: 0,
      };
      const fixedLayoutHeight = Math.ceil(
        (widgetHeight + MARGIN_CONTAINER + MARGIN_HEADER + ROW_HEIGHT) /
          (ROW_HEIGHT + MARGIN_CONTAINER)
      );

      this.makerFixLayoutHeight({
        layoutId: widgetId,
        height: fixedLayoutHeight,
      });
    },
  },
};
