<template>
  <DgCard v-if="!isEmpty" :class="classWidgetCard" :data-widget="widget.id">
    <LoadingWidget v-if="loading" :ref="`ld-${widget.id}`" />
    <div :id="widget.id" :class="classWidgetType" :style="styleWidgetText">
      <div v-if="widget.type === pageBreakType" class="page-break" />
      <div v-if="widget.type === layoutBreakType" class="layout-break" />
      <h4 class="dg-dashboard-widget__title">
        <img v-if="widget.icon" :src="widget.icon" class="ico-widget" />
        {{ widget.title }}
      </h4>
      <component
        ref="widgetComponent"
        v-if="currentWidget && widget"
        :is="currentWidget"
        :widget="widgetRemap"
        :widget-type="widgetTypeComp"
        :report-settings="reportSettings"
      />
    </div>
  </DgCard>
</template>
<script>
import { mapState } from "vuex";
import { dsWithoutTranslatableMetrics } from "@/constants/datasources";
import {
  translateSingleDimensionLabel,
  translateSingleMetricLabel,
} from "@/utils/formatGroupLabels";
import { DgCard } from "@engenharia/dashgoo-ui";
import {
  MANUAL_KPI,
  BAR_CHART,
  DYNAMIC_SUMMARY,
  LINE_CHART,
  OVERVIEW,
  PIE_CHART,
  RIVALS,
  STACK_BAR_CHART,
  TABLE,
  TEXT,
  WIDGETS,
  IMAGE,
  isEmpty,
} from "@engenharia/dashgoo-widgets";
import { PAGE_BREAK, LAYOUT_BREAK } from "@engenharia/dashgoo-widgets";
import LoadingWidget from "./Loading";

export default {
  name: "PageMakerRenderWidget",
  props: {
    widget: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    reportSettings: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    DgCard,
    LoadingWidget,
  },
  computed: {
    ...mapState({
      portalSettings: (state) => state.portal?.settings,
    }),
    isText() {
      if (this.widgetTypeComp === "text") {
        const isText = typeof this.widget?.data === "string";
        return isText ? this.widget?.data : "";
      } else {
        return false;
      }
    },
    errorIcon() {
      const widgetIcon = WIDGETS.find(
        ({ dataType }) => this.widgetType === dataType
      )?.icon;
      return {
        icon: true,
        [widgetIcon]: true,
      };
    },
    pageBreakType() {
      return PAGE_BREAK.dataType;
    },
    layoutBreakType() {
      return LAYOUT_BREAK.dataType;
    },
    widgetTypeComp() {
      return this.widget?.type;
    },
    isEmpty() {
      return isEmpty(this.widget, this.widgetTypeComp);
    },
    classWidgetCard() {
      const classWidgetCard = {
        "dg-dashboard-widget__card": true,
        "dg-dashboard-widget__card--has-text": this.isText,
        [`dg-dashboard-widget__card--${this.classWidgetType}`]: true,
      };
      return classWidgetCard;
    },
    classWidgetType() {
      const classWidget = `widget-${this.widgetTypeComp}`;
      return classWidget;
    },
    styleWidgetText() {
      const portalColor = this.portalSettings?.portalColor;
      const mainColor = portalColor === "#FFFFFF" ? "#000000" : portalColor;
      return {
        "--widget-text-color": mainColor,
      };
    },
    textClass() {
      return {
        "dg-dashboard-widget__card--has-text": this.isText,
      };
    },

    widgetRemap() {
      const { data, provider, service, ...restItem } = this.widget;
      const datasource = `${provider}-${service}`;
      const opts = { provider, service };

      const isTranslatableDatasource =
        !this.dsWithoutTranslatableMetrics(datasource);

      if (!isTranslatableDatasource) {
        return this.widget;
      }

      if (!data) {
        return { ...restItem, data };
      }

      if (Array.isArray(data)) {
        return {
          ...restItem,
          provider,
          service,
          data: this.translateMetrics(data, opts),
        };
      }

      if (typeof data === "object") {
        return {
          ...restItem,
          provider,
          service,
          data: this.translateDimensionsAndMetrics(data, opts),
        };
      }

      return this.widget;
    },
  },
  data: () => ({
    currentWidget: undefined,
    classe: {},
  }),
  methods: {
    dsWithoutTranslatableMetrics,
    setComponentWidget() {
      if (this.widgetTypeComp === OVERVIEW.dataType) {
        this.currentWidget = () =>
          import("@/components/Home/RenderWidget/Overview.vue");
        return;
      }

      if (this.widgetTypeComp === TABLE.dataType) {
        this.currentWidget = () =>
          import("@/components/Home/RenderWidget/Table.vue");
        return;
      }

      if (this.widgetTypeComp === RIVALS.dataType) {
        this.currentWidget = () =>
          import("@/components/Home/RenderWidget/Rivals.vue");
        return;
      }

      if (this.widgetTypeComp === IMAGE.dataType) {
        this.currentWidget = () =>
          import("@/components/Home/RenderWidget/Image.vue");
        return;
      }

      if (this.widgetTypeComp === TEXT.dataType) {
        this.currentWidget = () =>
          import("@/components/Home/RenderWidget/Text.vue");
        return;
      }

      if (this.widgetTypeComp === DYNAMIC_SUMMARY.dataType) {
        this.currentWidget = () =>
          import("@/components/Home/RenderWidget/DynamicSummary.vue");
        return;
      }

      if (this.widgetTypeComp === MANUAL_KPI.dataType) {
        this.currentWidget = () =>
          import("@/components/Home/RenderWidget/KpiManual.vue");
        return;
      }

      const WIDGETS_CHARTS = [
        LINE_CHART.dataType,
        BAR_CHART.dataType,
        PIE_CHART.dataType,
        STACK_BAR_CHART.dataType,
      ];
      if (WIDGETS_CHARTS.includes(this.widgetTypeComp)) {
        this.currentWidget = () =>
          import("@/components/Home/RenderWidget/Chart.vue");
        return;
      }
    },
    translateMetrics(dataArray, opts) {
      return dataArray.map((item) => {
        if (item.measureLabel) {
          item.measureLabel = translateSingleMetricLabel(
            item.measureLabel,
            opts
          );
        }
        return item;
      });
    },
    translateDimensionsAndMetrics(data, opts) {
      const { dimensionLabels = [], measureLabels = [] } = data;

      const translatedDimensionLabels = dimensionLabels.map((label) =>
        translateSingleDimensionLabel(label, opts)
      );

      const translatedMeasureLabels = measureLabels.map((label) =>
        translateSingleMetricLabel(label, opts)
      );

      return {
        ...data,
        dimensionLabels: translatedDimensionLabels,
        measureLabels: translatedMeasureLabels,
      };
    },
  },
  watch: {
    widget: {
      immediate: true,
      handler() {
        this.setComponentWidget();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.page-break {
  height: 0;
  width: 0;
}

.layout-break {
  height: 0;
  width: 0;
}
.widget-text {
  background: transparent;
  box-shadow: none;
  border-radius: 0;
  &::before {
    content: "";
    position: absolute;
    height: 33px;
    top: 20px;
    left: 15px;
    border-left-width: 5px;
    border-left-color: var(--widget-text-color);
    border-left-style: solid;
    border-radius: 100px;
  }

  & > .dg-dashboard-widget__title {
    color: var(--widget-text-color) !important;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
  }

  & > .dg-dashboard-widget__title,
  & > .dg-text {
    margin-left: 22px;

    @media (min-width: 768px) {
      margin-left: 14px;
    }
  }
}
.dg-dashboard-widget__title {
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  grid-gap: 0.25rem;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0;
  color: #526069 !important;

  @media (min-width: 768px) {
    grid-gap: 1rem;
    font-size: 28px;
  }

  & > img {
    width: 16px;

    @media (min-width: 768px) {
      width: 24px;
    }
  }
}

.dg-dashboard-widget__card.dg-card.dg-dashboard-widget__card--widget-text:not(
    .dg-dashboard-widget__card--has-text
  ),
.dg-dashboard-widget__card.dg-card.dg-dashboard-widget__card--widget-layout-break,
.dg-dashboard-widget__card.dg-card.dg-dashboard-widget__card--widget-page-break {
  background: transparent;
}

.dg-dashboard-widget__card.dg-card.dg-dashboard-widget__card--widget-text {
  height: auto;
}

.dg-dashboard-widget__card.dg-card {
  border-radius: 16px;
  box-shadow: none;
  padding: 0.5rem 0.25rem;
  height: 100%;
  overflow: auto;

  @media not print {
    @media screen and (min-width: 768px) {
      padding: 1rem;
      height: 100%;
      overflow: initial;
    }
  }

  &.dg-dashboard-widget__card__text {
    height: auto;
  }
}
</style>
