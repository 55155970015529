export const GOOGLE_ADWORDS = "google-adwords";
export const GOOGLE_ANALYTICS = "google-analytics";
export const GOOGLE_ANALYTICS4 = "google-analytics4";
export const GOOGLE_BUSINESS = "google-my-business";
export const GOOGLE_SHEETS = "google-sheets";
export const GOOGLE_SEARCH_CONSOLE = "google-search-console";
export const GOOGLE_YOUTUBE = "google-youtube";
export const FACEBOOK_ADS = "facebook-ads";
export const FACEBOOK_INSIGHTS = "facebook-insights";
export const FACEBOOK_INSTAGRAM = "facebook-instagram";
export const LINKEDIN_PAGES = "linkedin-pages";
export const LINKEDIN_ADS = "linkedin-ads";
export const LINKEDIN_PERSONAL = "linkedin-personal";
export const TWITTER_ANALYTICS = "twitter-analytics";
export const TWITTER_ADS = "twitter-ads";
export const RD_STATION = "rdstation-ads";
export const MAILCHIMP = "mailchimp";
export const TIKTOK_BUSINESS = "tiktok-business";
export const TIKTOK_ADS = "tiktok-ads";
export const GOOGLE_MYBUSINESS = "google-mybusiness";

// ao adicionar um novo datasource,
// incluir no array abaixo caso ele não possua métricas traduzíveis
export const dsWithoutTranslatableMetrics = (datasource) => {
  if (datasource.includes("undefined") || !datasource) return true;

  const notTranslatableDatasources = [
    GOOGLE_ADWORDS,
    GOOGLE_ANALYTICS,
    GOOGLE_BUSINESS,
    GOOGLE_SHEETS,
    GOOGLE_SEARCH_CONSOLE,
    FACEBOOK_ADS,
    FACEBOOK_INSTAGRAM,
    TWITTER_ANALYTICS,
    TWITTER_ADS,
    MAILCHIMP,
  ];

  return notTranslatableDatasources.includes(datasource);
};
