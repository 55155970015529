export const GRID_EVENTS = {
  DROP: "drop",
  DRAGEND: "dragend",
  DRAGSTART: "dragstart",
  DRAGOVER: "dragover",
};

export const GRID_CONFIGS = {
  colNum: 12,
  rowHeight: 36,
  margin: [16, 16],
};
