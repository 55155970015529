import { dsWithoutTranslatableMetrics } from "@/constants/datasources";
import i18n from "@/lib/i18n";

const translateGroupAndItems = (groups, itemPrefix, groupPrefix) =>
  groups.map(({ group = [], label = "" }) => {
    const formattedGroup = group.map(({ label: itemLabel, ...restGroup }) => ({
      ...restGroup,
      label: i18n.t(`${itemPrefix}${itemLabel}`),
    }));

    return {
      label: i18n.t(`${groupPrefix}${label}`),
      group: formattedGroup,
    };
  });

const getPrefixes = (opts) => {
  const { provider, service } = opts;
  const datasource = `${provider}-${service}`;

  const isTranslatableDatasource = !dsWithoutTranslatableMetrics(datasource);

  if (isTranslatableDatasource) {
    return {
      itemPrefix: `DATASOURCES.${datasource.toUpperCase()}.DIMENSIONS.`,
      groupPrefix: `DATASOURCES.${datasource.toUpperCase()}.DIMENSION_GROUPS.`,
      metricPrefix: `DATASOURCES.${datasource.toUpperCase()}.METRICS.`,
      metricGroupPrefix: `DATASOURCES.${datasource.toUpperCase()}.METRIC_GROUPS.`,
    };
  }

  return {
    itemPrefix: "",
    groupPrefix: "",
    metricPrefix: "",
    metricGroupPrefix: "",
  };
};

export const translateSingleDimensionLabel = (label, opts) => {
  const { itemPrefix } = getPrefixes(opts);
  return i18n.t(`${itemPrefix}${label}`);
};

export const translateDimensionLabels = (groups, opts) => {
  const { itemPrefix, groupPrefix } = getPrefixes(opts);
  return translateGroupAndItems(groups, itemPrefix, groupPrefix);
};

export const translateSingleMetricLabel = (label, opts) => {
  const { metricPrefix } = getPrefixes(opts);
  return i18n.t(`${metricPrefix}${label}`);
};

export const translateMetricLabels = (groups, opts) => {
  const { metricPrefix: itemPrefix, metricGroupPrefix: groupPrefix } =
    getPrefixes(opts);
  return translateGroupAndItems(groups, itemPrefix, groupPrefix);
};
