<template>
  <div class="loading__wrapper">
    <div class="loadbox">
      <div v-for="index in bars" :key="index" class="line" />
    </div>
    <p class="load_text">{{ $t("UPDATING_DATA") }}</p>
  </div>
</template>
<script lang="ts">
export default {
  name: "LoadingWidget",
  props: {
    bars: {
      type: Number,
      default: () => 6,
    },
  },
};
</script>
<style lang="scss" scoped>
.load_text {
  font-size: 13px;
  font-style: italic;
  color: #333;
  margin-top: 0px;
  padding: 0px 20px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    #cbcbcb,
    rgba(255, 255, 255, 0)
  );
  display: inline;
}
.loading__wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 80px;
  justify-content: flex-start;
  background: rgba(235, 235, 235, 0.8);
  z-index: 9;
  flex-direction: column;
  margin: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

.line {
  bottom: 0px;
  margin: 0px 1px;
  display: inline-flex;
  width: 12px;
  height: 10px;
  background-color: var(--primary);
}

.loadbox {
  display: flex;
  align-items: flex-end;
  position: relative;
  height: 38px;
}

.loadbox .line:nth-child(3n-2) {
  animation: cheerup 1.2s 1s infinite;
}

.loadbox .line:nth-child(3n-1) {
  animation: cheerup 1.2s 0.5s infinite;
}

.loadbox .line:nth-child(3n) {
  animation: cheerup 1.2s 0s infinite;
}

@keyframes cheerup {
  0% {
    height: 10px;
  }
  50% {
    height: 38px;
  }
  100% {
    height: 10px;
  }
}
</style>
